import React from 'react';
import PropTypes from 'prop-types';

import {
  FloatPanel,
  FrameEditor,
  FrameEditorContent
} from '@common/components/FloatPanel';

const RawBlockComponent = props => {
  const content =
    props?.content ?? "<h1 style='padding: 40px; text-align: center;'>Here will be your code</h1>";
  const blockID = props?.rootContext?.blockId ?? '';
  const fragmentID = props?.fragmentEntity?.id;
  const mode = props?.rootContext?.mode ?? 'public';

  if (mode !== 'editor') {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  }

  return (
    <FrameEditor>
      <FloatPanel
        blockId={blockID}
        link={{
          editFragment: `/admin/fragment/${fragmentID}`
        }}
        actions={['delete', 'editFragment']}
      />
      <div dangerouslySetInnerHTML={{ __html: content }} />
      <FrameEditorContent />
    </FrameEditor>
  );
};

RawBlockComponent.propTypes = {
  rootContext: PropTypes.shape({
    blockId: PropTypes.string.isRequired,
    // Мод в котором находится пользователь для отображения управления
    mode: PropTypes.oneOf(['public', 'editor', 'preview'])
  }),
  fragmentEntity: PropTypes.shape({
    id: PropTypes.string
  }),
  content: PropTypes.string
};

export default RawBlockComponent;
