import ActionTypes from "./ActionTypes";
import Dispatcher from "./Flux";

class RenderStore {
  blocks = {};
  entities = {};
  store = {};

  constructor() {
    Dispatcher.register(this.reduceNew.bind(this));
  }

  setRefeatch(rootContext, refetch) {
    this.blocks[rootContext.blockId] = { refetch };
    if (this.entities[rootContext.id]) {
      if (-1 === this.entities[rootContext.id].indexOf(rootContext.blockId)) {
        this.entities[rootContext.id].push(rootContext.blockId);
        Dispatcher.register(this.reduce.bind(this));
      }
    } else {
      this.entities[rootContext.id] = [rootContext.blockId];
      Dispatcher.register(this.reduce.bind(this));
    }
  }

  reduce(action) {
    switch (action.type) {
      case ActionTypes.RENDER:
        if (this.entities[action.id] && this.entities[action.id].length > 0) {
          this.entities[action.id].map(blockId => {
            this.blocks[blockId].refetch();
          });
        }
        return null;

      default:
        return null;
    }
  }

  subscribe(...objs) {
    objs.map(obj => {
      if (!this.store.hasOwnProperty(obj.entityID)) {
        this.store[obj.entityID] = {};
      }

      this.store[obj.entityID][obj.blockID] = obj.refetchFunc;
    });
  }

  reduceNew(action) {
    switch (action.type) {
      case ActionTypes.RENDER:
        const blocks = this.store[action.id] || {};
        const blockIDs = Object.keys(blocks);
        blockIDs.map(blockID => {
          this.store[action.id][blockID]();
        });
        return null;

      default:
        return null;
    }
  }
}

export default new RenderStore();
