import ActionTypes from "./ActionTypes";
import Dispatcher from "./Flux";

const Actions = {
  moveDown(blockId, sectionId) {
    Dispatcher.dispatch({
      action: ActionTypes.MOVE_DOWN,
      blockId,
      sectionId
    });
  },

  moveUp(blockId, sectionId) {
    Dispatcher.dispatch({
      action: ActionTypes.MOVE_UP,
      blockId,
      sectionId
    });
  },

  delete(blockId) {
    Dispatcher.dispatch({
      action: ActionTypes.DELETE_SECTION,
      blockId
    });
  },

  cartUpdate() {
    Dispatcher.dispatch({
      type: ActionTypes.CART_UPDATE
    });
  },

  editorsPanelVisibility(unitId, sectionId, mode) {
    Dispatcher.dispatch({
      type: ActionTypes.EDITORS_PANEL_VISIBILITY,
      unitId,
      sectionId,
      mode
    });
  },

  layoutPanelUpdate() {
    Dispatcher.dispatch({
      type: ActionTypes.LAYOUT_PANEL_UPDATE
    });
  },

  checkSiteList() {
    Dispatcher.dispatch({
      type: ActionTypes.CHECK_SITE_LIST
    });
  },

  nhGiveDataEditorsBlock(blockId) {
    Dispatcher.dispatch({
      type: ActionTypes.NH_GIVE_DATA_EDITORS_BLOCK,
      blockId
    });
  },

  nhReturnDataEditorsBlock(data) {
    Dispatcher.dispatch({
      type: ActionTypes.NH_RETURN_DATA_EDITORS_BLOCK,
      data
    });
  },

  nhEditorsPanelPrivat(unitId, mode, content) {
    Dispatcher.dispatch({
      type: ActionTypes.NH_EDITORS_PANEL_PRIVAT,
      unitId,
      mode,
      content
    });
  },

  nhSendDataEditorPrivat(data) {
    Dispatcher.dispatch({
      type: ActionTypes.NH_SEND_DATA_EDITOR_PRIVAT,
      data
    });
  },

  nhReturnDataEditorPrivat(data) {
    Dispatcher.dispatch({
      type: ActionTypes.NH_RETURN_DATA_EDITOR_PRIVAT,
      data
    });
  },

  nhSuccessSaveFragmentPrivat(fragmentId) {
    Dispatcher.dispatch({
      type: ActionTypes.NH_SUCCESS_SAVE_FRAGMENT_PRIVAT,
      fragmentId
    });
  },

  nhBlockFragmentRefetch(fragmentId) {
    Dispatcher.dispatch({
      type: ActionTypes.NH_BLOCK_FRAGMENT_REFETCH,
      fragmentId
    });
  }
};

export default Actions;
