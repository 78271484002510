import { Dispatcher } from "flux";

let dispatcher;
if (typeof window !== "undefined") {
  if (!window.__NETHOUSE_DISPATCHER__) {
    window.__NETHOUSE_DISPATCHER__ = new Dispatcher();
  }
  dispatcher = window.__NETHOUSE_DISPATCHER__;
} else {
  dispatcher = new Dispatcher();
}

export default dispatcher;
