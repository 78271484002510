import React from 'react';
import { graphql, compose } from 'react-apollo';
import { mapProps } from 'recompose';

import { withFragmentEntitySubscription } from '@common/hoc/withFragmentEntitySubscription';
import RawBlockComponent from './RawBlockComponent';
import GET_FRAGMENT from '../fragment';

const ComponentWithFragmentEntitySubscription = withFragmentEntitySubscription(
  RawBlockComponent
);

const ComponentWithFragmentProps = mapProps(props => {
  return {
    ...props,
    ...props.fragment.fragmentPayload.fragment.props
  };
})(ComponentWithFragmentEntitySubscription);

const RawBlockContainer = compose(
  graphql(GET_FRAGMENT, {
    name: 'fragment',
    options: props => {
      return {
        variables: {
          id: props.fragmentEntity.id
        },
        errorPolicy: 'ignore'
      };
    }
  })
)(ComponentWithFragmentProps);

export default RawBlockContainer;
