import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "react-emotion";

import Actions from "../../data/Actions";

import StyledIcon from "../StyledIcon";
import {
  ArrowUpIcon,
  ArrowDownIcon,
  GearIcon,
  TrashIcon,
  Palette
} from "./assets/icons";

const FrameEditorContent = styled("div")``;

const FloatPanelContent = styled("div")`
  position: absolute;
  top: -43px;
  left: 0;
  display: none;
  width: 100%;
  z-index: 20;
  &:hover + ${FrameEditorContent} {
    &:before {
      content: "";
      position: absolute;
      top: -3px;
      left: 0;
      right: 0;
      bottom: -3px;
      background: rgba(19, 41, 61, 0.7);
      border: solid #181e22;
      border-width: 3px 3px 3px 0;
      z-index: 1;
    }
  }
`;

const FrameEditor = styled("div")`
  position: relative;
  box-shadow: 0 0 0 3px transparent;
  transition: box-shadow 0.1s ease-out;
  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    z-index: 1;
  }
  &:before {
    right: 3px;
    left: 0;
    height: 3px;
  }
  &:after {
    top: 0;
    right: 0;
    width: 3px;
  }
  &:hover {
    box-shadow: inset 3px 0 0 0 transparent, inset 0 3px 0 0 transparent, 0 -3px 0 0 rgba(24, 30, 34, 0.5), inset -3px 0 0 0 transparent;
    &:before,
    &:after {
      background: rgba(24, 30, 34, 0.5);
    }
    ${FloatPanelContent} {
      display: block;
    }
  }
}
`;

const StyleFPGroup = css`
  overflow: hidden;
  background: #181e22;
  border-bottom: 3px solid #181e22;
  border-radius: 14px 14px 0 0;
  opacity: 0.5;
  box-shadow: 0 0 30px rgba(0, 29, 74, 0.25);
  &:hover {
    opacity: 1;
  }
`;

const StyleFPBox = css`
  position: absolute;
  top: 0;
  right: 0;
  border-bottom: 3px solid transparent;
`;

const StyleFPButtonBase = css`
  cursor: pointer;
  display: inline-block;
  padding: 12px;
  background-color: transparent;
  line-height: 1;
  vertical-align: top;
  text-transform: uppercase;
  color: #fff;
  transition: all ease-out 0.1s;
`;

const StyleFPButtonDefault = css`
  ${StyleFPButtonBase};
  &:hover,
  &:focus {
    background-color: #fff;
    color: #181e22;
  }
`;

const StyleFPButtonDelete = css`
  ${StyleFPButtonBase};
  &:hover,
  &:focus {
    background-color: #fe4a49;
    color: #fff;
  }
`;

const StyleFPButtonGradient = css`
  ${StyleFPButtonBase};
  &:hover,
  &:focus {
    background: #715aff linear-gradient(45deg, #715aff 2.5%, #ea3788 100%);
    color: #fff;
  }
`;

class FloatPanel extends React.Component {
  state = {
    blockId: "",
    link: {
      edit: ""
    }
  };

  constructor(props) {
    super(props);
    const { blockId, link } = this.props;
    this.state = {
      blockId,
      link
    };
  }

  onDelete() {
    Actions.delete(this.state.blockId);
  }

  moveUp() {
    Actions.moveUp(this.state.blockId, this.state.blockId);
  }

  moveDown() {
    Actions.moveDown(this.state.blockId, this.state.blockId);
  }

  goToSettings() {
    window.location.assign(`#${this.state.link.edit}`);
  }

  showAction(action) {
    return (this.props.actions || []).indexOf(action) >= 0;
  }

  goToFragmentEditor() {
    window.location.assign(`#${this.state.link.editFragment}/${FRAGMENT_PATH}`);
  }

  allowEdit() {
    return typeof this.state.link.edit === "undefined" ? false : true;
  }

  allowEditFragment() {
    if (typeof this.state.link.editFragment === "undefined") {
      return false;
    }

    if (
      typeof this.state.link.editFragment === "string" &&
      this.state.link.editFragment.indexOf("qwerty") !== -1
    ) {
      return false;
    }

    if (
      typeof this.state.link.editFragment === "string" &&
      this.state.link.editFragment.indexOf(
        "4b07593abd40f57e85250272ec99d9e4"
      ) !== -1
    ) {
      return false;
    }

    if (
      typeof this.state.link.editFragment === "string" &&
      this.state.link.editFragment.indexOf(
        "40ac919c5d8c55f3b52f1b4030598bca"
      ) !== -1
    ) {
      return false;
    }

    if (
      typeof this.state.link.editFragment === "string" &&
      this.state.link.editFragment.indexOf(
        "431dcb9f28238d178c8e4ccb39474638"
      ) !== -1
    ) {
      return false;
    }

    if (
      typeof this.state.link.editFragment === "string" &&
      this.state.link.editFragment.indexOf(
        "44ec03f11dcc6ab59f9bd09f49badcca"
      ) !== -1
    ) {
      return false;
    }

    if (typeof FRAGMENT_PATH !== "string" && FRAGMENT_PATH.length === 0) {
      return false;
    }

    return true;
  }

  render() {
    return (
      <FloatPanelContent>
        <div className={StyleFPBox}>
          <div className={StyleFPGroup}>
            {this.showAction("delete") && (
              <div
                onClick={this.onDelete.bind(this)}
                className={StyleFPButtonDelete}
              >
                <StyledIcon width={16} height={16}>
                  <TrashIcon />
                </StyledIcon>
              </div>
            )}
            <div
              onClick={this.moveUp.bind(this)}
              className={StyleFPButtonDefault}
            >
              <StyledIcon width={16} height={16}>
                <ArrowUpIcon />
              </StyledIcon>
            </div>
            <div
              onClick={this.moveDown.bind(this)}
              className={StyleFPButtonDefault}
            >
              <StyledIcon width={16} height={16}>
                <ArrowDownIcon />
              </StyledIcon>
            </div>
            {this.allowEditFragment() && (
              <div
                onClick={this.goToFragmentEditor.bind(this)}
                className={StyleFPButtonGradient}
              >
                <StyledIcon width={16} height={16}>
                  <Palette />
                </StyledIcon>
              </div>
            )}
            {this.allowEdit() && (
              <div
                onClick={this.goToSettings.bind(this)}
                className={StyleFPButtonDefault}
              >
                <StyledIcon width={16} height={16}>
                  <GearIcon />
                </StyledIcon>
              </div>
            )}
          </div>
        </div>
      </FloatPanelContent>
    );
  }

  static propTypes = {
    blockId: PropTypes.string.isRequired,
    link: PropTypes.object,
    actions: PropTypes.array
  };
}

export default FloatPanel;
export { FrameEditor, FrameEditorContent };
