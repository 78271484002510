const ActionTypes = {
  RENDER: "RENDER",
  MOVE_UP: "MOVE_UP",
  MOVE_DOWN: "MOVE_DOWN",
  DELETE_SECTION: "DELETE_SECTION",
  CART_UPDATE: "CART_UPDATE",
  CHECK_SITE_LIST: "CHECK_SITE_LIST",
  EDITORS_PANEL_VISIBILITY: "EDITORS_PANEL_VISIBILITY",
  LAYOUT_PANEL_UPDATE: "LAYOUT_PANEL_UPDATE",
  NH_GIVE_DATA_EDITORS_BLOCK: "NH_GIVE_DATA_EDITORS_BLOCK",
  NH_RETURN_DATA_EDITORS_BLOCK: "NH_RETURN_DATA_EDITORS_BLOCK",
  NH_EDITORS_PANEL_PRIVAT: "NH_EDITORS_PANEL_PRIVAT",
  NH_SEND_DATA_EDITOR_PRIVAT: "NH_SEND_DATA_EDITOR_PRIVAT",
  NH_RETURN_DATA_EDITOR_PRIVAT: "NH_RETURN_DATA_EDITOR_PRIVAT",
  NH_SUCCESS_SAVE_FRAGMENT_PRIVAT: "NH_SUCCESS_SAVE_FRAGMENT_PRIVAT",
  NH_BLOCK_FRAGMENT_REFETCH: "NH_BLOCK_FRAGMENT_REFETCH"
};

export default ActionTypes;
