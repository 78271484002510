import gql from 'graphql-tag';

const GET_FRAGMENT = gql`
  query pageFragment($id: ID) {
    fragmentPayload(id: $id)
      @rest(type: "FragmentPayload", path: "/:id", endpoint: "fragment") {
      fragment @type(name: "FragmentPayloadData") {
        id
        props @type(name: "Props") @edit(as: "Panel") {
          content
            @edit(
              as: "Code"
              label: "HTML Code"
              value: "Write your old school html"
            )
        }
      }
    }
  }
`;

export default GET_FRAGMENT;
